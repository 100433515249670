body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 0.8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.menu-container {
  position: fixed;
  width: fit-content;
  top: 15vh;
  height: 75vh;
  left: 70px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-120px);
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.menu-container > img {
  width: 250px;
  margin-left: 15px;
  border-radius: 10px;
}

.tv-menu {
  /* position: absolute; */
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  width: fit-content;
  max-height: 100%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-120px);
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.MainMenu {
  visibility: hidden;
  opacity: 0;
  transform: translateX(-120px);
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.MainMenu > .btn-main {
  width: 50px;
  height: 50px;
  background-color: #000;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 10px;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 80%;
  display: flex;
  padding: 5px;
}

.menu .MainMenu {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.menu .MainMenu > .btn-main-tv {
  background-color: rgba(0, 119, 255, 0.562) !important;
}

.menu .tv-menu, .menu .menu-container {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}


.MenuList {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.tv-menu > div + div.MenuListActive {
  border-left: 7px solid rgba(0, 119, 255, 0.562);
}

.tv-menu > div {
  overflow-y: hidden;
  visibility: hidden;
  opacity: 0;
  transform: scaleX(0);
  transform-origin: top left;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.tv-menu > div.MenuListActive {
  visibility: visible;
  opacity: 1;
  transform: scaleX(1);
}

.MenuList-item + .MenuList-item {
  margin-top: 1px;
}
.MenuList-item {
  display: none;
  align-items: center;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  background-color: black;
  text-decoration: none;
  outline: none;
  gap: 10px !important;
  white-space : nowrap;
  overflow : hidden;
  -o-text-overflow : ellipsis; 
  text-overflow :    ellipsis;
  height: 18px;
}

.MenuList-item, .MenuList-item span {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.MenuList-item span {
  display: inline-block;
  margin: -10px;
  margin-right: 5px !important;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  min-width: 17px;
  padding-right: 10px;
  text-align: center;
}

.MenuList-item img {
  width: 25px;
  margin-right: 5px;
}

.MenuList-item.active {
  background-color: rgba(0, 119, 255, 0.562);
}

.MenuList-item.active span {
  background-color: rgba(0, 0, 0, 0.418);
}


.MenuList-item.focus {
  background-color: rgb(0, 119, 255);
  color: #fff;
  outline: none;
  transform: scaleY(1.1);
}

.MenuList-item.focus span {
  background-color: rgba(0, 0, 0, 0.5);
}

.reload {
  position: absolute;
  top: 10px;
  left: 10px;
}

.DetailBanner {
  visibility: hidden;
  transform: scaleX(0) translateY(20px);
  opacity: 0;
  position: absolute;
  bottom: 10vh;
  left: 15vw;
  right: 15vw;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  color: #fff;
  display: flex;
  padding: 10px;
  overflow: hidden;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  border: 1px solid rgba(0, 119, 255, 0.562);
}

.bottom-detail .DetailBanner {
  visibility: visible;
  opacity: 1;
  transform: scaleX(1) translateY(0);
}

.DetailBanner .title {
  flex: 1;
}

.DetailBanner .title h1 {
  margin: 0;
}

.DetailBanner .title p {
  margin: 5px 0 0 0;
}

.DetailBanner .num {
  font-size: 50px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 119, 255, 0.562);
  margin: -10px;
  margin-right: 10px;
}

.DetailBanner > img {
  margin-left: 15px;
  border-radius: 5px;
  max-width: 35%;
}

.loader-container {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loader {
  position: absolute;
  transform: scale(0) translateY(-70px);
  opacity: 0;
  visibility: hidden;
  top: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.loader.loading {
  transform: scale(1) translateY(0) !important;
  opacity: 1;
  visibility: visible;
}

pre.pre{
  background-color: #fff;
  position: absolute;
  z-index: 1111;
  white-space: pre-line;
}

.MainMenu {
  position: absolute;
  top: 10vh;
  left: 0;
  width: 50px;
  display: flex;
  flex-direction: column;
}

.mylogo {
  position: absolute;
  top: 0;
  left: 0;
  height: 10vh;
}

.input {
  width: 100%;
  font-size: 18px;
  padding: 5px;
  margin: 2px 0;
  border-radius: 5px;
}

* {
  box-sizing: border-box;
}